import React from 'react';

import Text from 'components/Common/Text';

import { ReactComponent as HumanIcon } from 'assets/svg/human.svg';

import styles from './styles.module.scss';

const View = ({ name }) => (
  <div className={styles.profile}>
    <HumanIcon className={styles.humanIcon} />
    <Text color="red" size="xs" weight="bold" className={styles.profileText}>
      {name}
    </Text>
  </div>
);

export default View;
