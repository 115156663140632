import axios from 'axios';

import { getLocalStorageItem } from 'helpers/localStorage';

const defaultOptions = {
  method: 'POST',
  headers: {
    'content-type': 'application/json',
  },
};

const request = async ({ url, options }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getLocalStorageItem('token');

  const endpoint = `${apiUrl}${url}`;

  const compiledOptions = { ...defaultOptions, ...options };

  if (token && compiledOptions.headers) {
    compiledOptions.headers.Authorization = `token ${token}`;
  }

  if (!token && compiledOptions.headers) {
    compiledOptions.headers.Authorization = null;
  }

  const response = await axios({
    url: endpoint,
    headers: new Headers(compiledOptions.headers),
    ...compiledOptions,
  });

  return response;
};

export { request };
