import { createSlice } from '@reduxjs/toolkit';

import { removeLocalStorageItem, getLocalStorageItem } from 'helpers/localStorage';
import asyncActions from './asyncThunks';

const dropToken = (state) => {
  removeLocalStorageItem('token');
  state.auth.data = null;
};

const initialState = {
  nominants: {
    data: null,
    isLoading: false,
    error: null,
  },
  voteInfo: {
    data: null,
    isLoading: false,
    error: null,
  },
  auth: {
    error: null,
    data: getLocalStorageItem('token'),
    isLoading: false,
  },
  userInfo: {
    error: null,
    data: null,
    isLoading: false,
  },
  logOut: {
    isLoading: false,
    error: null,
  },
  errors: {
    email: null,
    password: null,
    non_field_errors: null,
    detailLogOut: null,
    detailNominantList: null,
    detailVoteInfo: null,
  },
};

const reducers = {
  setError(state, { payload: { errorField, value } }) {
    state.errors[errorField] = value;
  },
};

const extraReducers = {
  [asyncActions.authUser.pending]: (state) => {
    state.auth.isLoading = true;
    state.auth.error = null;
  },
  [asyncActions.authUser.fulfilled]: (state, { payload: { auth_token: token } }) => {
    state.auth.data = token;
    state.auth.isLoading = false;
    state.auth.error = null;
  },
  [asyncActions.authUser.rejected]: (state, { error, payload: errorMessage }) => {
    if (errorMessage) {
      const errorKeys = Object.keys(errorMessage);
      const authError = errorKeys.reduce(
        (prevErrors, errorName) => ({ ...prevErrors, [errorName]: errorMessage[errorName] }),
        {},
      );

      state.errors = { ...state.error, ...authError };
    }

    state.auth.error = error;
    state.auth.isLoading = false;
  },

  [asyncActions.getUserInfo.pending]: (state) => {
    state.userInfo.isLoading = true;
    state.userInfo.error = null;
  },

  [asyncActions.getUserInfo.fulfilled]: (
    state,
    { payload: { first_name: firstName, last_name: lastName } },
  ) => {
    if (firstName && lastName) {
      state.userInfo.data = `${firstName} ${lastName}`;
    }

    state.userInfo.isLoading = false;
    state.userInfo.error = null;
  },

  [asyncActions.getUserInfo.rejected]: (state, { error, payload: errorDetail }) => {
    if (errorDetail?.detail) {
      state.errors = { ...state.errors, detailUserInfo: errorDetail.detail };
    }

    state.userInfo.error = error;
    state.userInfo.isLoading = false;
  },

  [asyncActions.logOutUser.pending]: (state) => {
    state.logOut.isLoading = true;
    state.logOut.error = null;
  },

  [asyncActions.logOutUser.fulfilled]: (state) => {
    dropToken(state);
    state.logOut.isLoading = false;
    state.logOut.error = null;
  },

  [asyncActions.logOutUser.rejected]: (state, { error, payload: errorDetail }) => {
    dropToken(state);

    if (errorDetail?.detail) {
      state.errors = { ...state.errors, detailLogOut: errorDetail.detail };
    }

    state.logOut.isLoading = false;
    state.logOut.error = error;
  },

  [asyncActions.getNominants.pending]: (state) => {
    state.nominants.isLoading = true;
  },

  [asyncActions.getNominants.fulfilled]: (state, { payload: nominants }) => {
    state.nominants.data = nominants;
    state.nominants.isLoading = false;
  },

  [asyncActions.getNominants.rejected]: (state, { error, payload: errorDetail }) => {
    dropToken(state);

    if (errorDetail?.detail) {
      state.errors = { ...state.errors, detailNominant: errorDetail.detail };
    }

    state.nominants.isLoading = false;
    state.nominants.error = error;
  },

  [asyncActions.sendVote.pending]: (state) => {
    state.voteInfo.isLoading = true;
  },

  [asyncActions.sendVote.fulfilled]: (state, { payload: voteInfo }) => {
    const { nominant: nominantId, points } = voteInfo;

    const stateNominants = [...state.nominants.data];

    const updatedNominants = stateNominants.reduce((prevNominants, currentNominant) => {
      const { id } = currentNominant;

      if (id === nominantId) {
        return [...prevNominants, { ...currentNominant, vote: points }];
      }

      return [...prevNominants, currentNominant];
    }, []);

    state.nominants.data = updatedNominants;
    state.voteInfo.data = voteInfo;
    state.voteInfo.isLoading = false;
  },

  [asyncActions.sendVote.rejected]: (state, { error, payload: errorDetail }) => {
    dropToken(state);

    if (errorDetail?.detail) {
      state.errors = { ...state.errors, detailVoteInfo: errorDetail.nominant };
    }

    state.voteInfo.isLoading = false;
    state.voteInfo.error = error;
  },
};

const { actions, reducer } = createSlice({
  name: 'store',
  reducers,
  initialState,
  extraReducers,
});

const selectors = {
  getAuthData: (state) => state.auth.data,
  getAuthError: (state) => state.auth.error,
  getIsAuthLoading: (state) => state.auth.isLoading,
  getLogoutLoading: (state) => state.logOut.isLoading,
  getUserInfo: (state) => state.userInfo.data,
  getNominants: (state) => state.nominants.data,
  getNominantsIsLoading: (state) => state.nominants.isLoading,
  getNominantsError: (state) => state.nominants.error,
  getVoteInfoLoading: (state) => state.voteInfo.isLoading,
  getCommonErrors: (state) => state.errors,
};

export { actions, selectors, asyncActions };

export default reducer;
