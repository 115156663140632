import React, { useState, useEffect } from 'react';

import View from './view';

const Controller = ({ ...rest }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return <View screenWidth={screenWidth} screenHeight={screenHeight} {...rest} />;
};

export default Controller;
