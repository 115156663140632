import React from 'react';
import cn from 'classnames';

import Text from 'components/Common/Text';

import styles from './style.module.scss';

const getErrorMessage = (errorMessage) => {
  if (!errorMessage) {
    return null;
  }

  const errorText = errorMessage.map((textError, key) => (
    <div key={key} className={styles.errorTextWrapper}>
      <Text size="xs" color="red">
        {textError}
      </Text>
    </div>
  ));

  return errorText;
};

const View = ({ placeholder, errorMessage, isError, className, value, onChange, type }) => {
  const errorMessageStyle = errorMessage || isError ? styles.inputError : null;
  const inputStyles = cn(styles.input, errorMessageStyle);

  return (
    <div className={cn(styles.inputWrapper, className)}>
      <input
        value={value}
        onChange={onChange}
        type={type}
        className={inputStyles}
        placeholder={placeholder}
      />
      {getErrorMessage(errorMessage)}
    </div>
  );
};

export default View;
