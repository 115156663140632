import sizes from 'styles/sizes.module.scss';

const getBreakPointSize = (size) => {
  const breakpointName = `${size}_SCREEN_SIZE`.toUpperCase();

  if (!sizes[breakpointName]) {
    return null;
  }

  const breakpoint = sizes[breakpointName].replace(/[a-zA-Z ]/g, '');

  return Number(breakpoint) || 0;
};

export default getBreakPointSize;
