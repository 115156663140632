import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import Auth from 'pages/Auth';
import PersonalArea from 'pages/PersonalArea';

import AuthContainer from 'components/Common/AuthContainer';

import routeNames from 'constants/routeNames';

import store from 'store';

import 'styles/global.scss';
import 'normalize.css';

const App = () => (
  <StoreProvider store={store}>
    <Router>
      <AuthContainer>
        <Switch>
          <Auth path={routeNames.auth} />
          <PersonalArea path={routeNames.personalArea} />
          <Redirect to="/" />
        </Switch>
      </AuthContainer>
    </Router>
  </StoreProvider>
);

export default App;
