import React from 'react';
import cn from 'classnames';

import Text from 'components/Common/Text';

import styles from './styles.module.scss';

const View = ({ label, logo, photo, presentation, video }) => {
  const presentationStyle = !presentation ? styles.disabledLink : null;
  const videoStyle = !video ? styles.disabledLink : null;
  const presentationTextColor = presentation ? 'white' : 'black';
  const videoTextColor = video ? 'white' : 'black';

  return (
    <div className={styles.container}>
      <Text size="xs" color="darkGrey" className={styles.blockLabel}>
        {label}
      </Text>
      <div className={styles.contentContainer}>
        <div className={styles.photoContainer}>
          <img src={logo} alt="logo" />
          <a
            className={styles.photoLink}
            title="Открыть изображение в новой вкладке"
            href={photo}
            target="_blank"
            rel="noreferrer nofollow"
          >
            <img src={photo} alt="photo" />
          </a>
        </div>
        <div className={styles.buttonsContainer}>
          <a
            className={cn(styles.link, presentationStyle)}
            href={presentation}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text size="s" color={presentationTextColor}>
              Презентация
            </Text>
          </a>
          <a
            className={cn(styles.link, videoStyle)}
            href={video}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text size="s" color={videoTextColor}>
              Ссылка на видеоролик
            </Text>
          </a>
        </div>
      </div>
    </div>
  );
};
export default View;
