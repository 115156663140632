import React, { useEffect } from 'react';

import View from './view';

const Controller = ({ getNominants, ...rest }) => {
  useEffect(() => {
    getNominants();
  }, []);

  return <View {...rest} />;
};

export default Controller;
