import React, { useState, useEffect } from 'react';

import View from './view';

const Controller = ({
  authUser,
  setError,
  commonErrors: { email: emailError, password: passwordError, non_field_errors: nonFieldError },
  ...rest
}) => {
  const [loginValue, setLoginValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const onChangeLogin = ({ target: { value: loginValue } }) => {
    if (emailError || nonFieldError) {
      setError({ errorField: 'email', value: null });
      setError({ errorField: 'non_field_errors', value: null });
    }

    setLoginValue(loginValue);
  };

  const onChangePass = ({ target: { value: passValue } }) => {
    if (passwordError || nonFieldError) {
      setError({ errorField: 'password', value: null });
      setError({ errorField: 'non_field_errors', value: null });
    }

    setPassValue(passValue);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    authUser({ password: passValue, email: loginValue });
  };

  return (
    <View
      onSubmit={onSubmit}
      loginValue={loginValue}
      passValue={passValue}
      onChangeLogin={onChangeLogin}
      onChangePass={onChangePass}
      emailError={emailError}
      passwordError={passwordError}
      nonFieldError={nonFieldError}
      screenWidth={screenWidth}
      {...rest}
    />
  );
};

export default Controller;
