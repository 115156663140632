import React, { useState, useEffect } from 'react';

import View from './view';

const Controller = ({ ...rest }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return <View screenWidth={screenWidth} {...rest} />;
};

export default Controller;
