import React from 'react';

import Text from 'components/Common/Text';
import TextField from 'components/Common/TextField';
import Button from 'components/Common/Button';
import Loader from 'components/Common/Loader';

import getBreakPointSize from 'helpers/getBreakPointSize';

import colors from 'styles/colors.module.scss';
import styles from './styles.module.scss';

const S_SCREEN_SIZE = getBreakPointSize('s');

const getContentButton = (authIsLoading) => {
  if (!authIsLoading) {
    return 'Войти в личный кабинет';
  }

  return <Loader color={colors.WHITE} width={54} height={30} />;
};

const getCommonErrorMessage = (commonErrorMessage) => {
  if (!commonErrorMessage) {
    return null;
  }

  const errorText = commonErrorMessage.map((textError, key) => (
    <div key={key} className={styles.errorTextWrapper}>
      <Text size="xs" color="red">
        {textError}
      </Text>
    </div>
  ));

  return errorText;
};

const View = ({
  onSubmit,
  loginValue,
  passValue,
  onChangeLogin,
  onChangePass,
  authIsLoading,
  emailError,
  passwordError,
  nonFieldError,
  screenWidth,
}) => {
  const textButtonSize = screenWidth <= S_SCREEN_SIZE ? 'xs' : 'm';

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <Text size="xs" color="lightGrey" className={styles.formTitle}>
        Введите данные
      </Text>
      <TextField
        value={loginValue}
        onChange={onChangeLogin}
        placeholder="Логин"
        errorMessage={emailError}
        isError={nonFieldError}
        className={styles.loginInput}
        type="text"
      />
      <TextField
        value={passValue}
        onChange={onChangePass}
        placeholder="Пароль"
        errorMessage={passwordError}
        isError={nonFieldError}
        type="password"
      />
      {getCommonErrorMessage(nonFieldError)}
      <div className={styles.authButtonWrapper}>
        <Button type="submit" className={styles.authButton} textSize={textButtonSize}>
          {getContentButton(authIsLoading)}
        </Button>
      </div>
    </form>
  );
};

export default View;
