import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import reducer from './slice.js';
import { actions, selectors, asyncActions } from './slice.js';

const middleware = process.env.REACT_APP_PRODUCTION
  ? null
  : { middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger) };

const store = configureStore({
  reducer,
  ...middleware,
});

export { actions, selectors, asyncActions };
export default store;
