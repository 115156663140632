import React from 'react';
import { HashLink } from 'react-router-hash-link';

import Text from 'components/Common/Text';
import Button from 'components/Common/Button';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';

import getBreakPointSize from 'helpers/getBreakPointSize';

import styles from './styles.module.scss';

const S_SCREEN_SIZE = getBreakPointSize('s');

const scrollToVoteBlock = ({ offsetTop: voteBlcokOffset }, screenHeight) => {
  const anchorOffset = screenHeight / 2;

  window.scroll({
    top: voteBlcokOffset - anchorOffset,
    left: 0,
    behavior: 'smooth',
  });
};

const View = ({ vote, isOpen, setIsOpen, voteBlockId, screenWidth, screenHeight }) => {
  const isNotVoted = vote === null;
  const isSmallScreen = screenWidth <= S_SCREEN_SIZE;
  const voteButtonText = isNotVoted ? 'Голосовать' : 'Голос отдан';
  const voteText = isNotVoted ? '_' : `${vote} | 10`;
  const arrowDirection = { transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' };
  const openButtonText = isOpen ? 'Закрыть' : 'Открыть';

  return (
    <div className={styles.bottomBar}>
      <button
        className={styles.openButton}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <ArrowIcon style={arrowDirection} className={styles.openIcon} />
        {!isSmallScreen && <Text>{openButtonText}</Text>}
      </button>
      <div className={styles.voteLabel}>
        {!isSmallScreen && <Text size="s">Баллов:</Text>}
        <Text color="red" size="l">
          {voteText}
        </Text>
      </div>
      {isNotVoted ? (
        <HashLink
          onClick={() => setIsOpen(true)}
          className={styles.hashLink}
          to={`#${voteBlockId}`}
          scroll={(voteBlock) => scrollToVoteBlock(voteBlock, screenHeight)}
        >
          <Button className={styles.voteButton} size="s">
            {voteButtonText}
          </Button>
        </HashLink>
      ) : (
        <Button className={styles.voteButton} size="s" disabled>
          {voteButtonText}
        </Button>
      )}
    </div>
  );
};

export default View;
