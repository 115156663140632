import { connect } from 'react-redux';

import Controller from './controller';

import { asyncActions, selectors } from 'store';

const mapStateToProps = (state) => ({
  nominants: selectors.getNominants(state),
  isLoading: selectors.getNominantsIsLoading(state),
});

const mapDispatchToProps = {
  getNominants: asyncActions.getNominants,
};

const ConnectedController = connect(mapStateToProps, mapDispatchToProps)(Controller);

export default ConnectedController;
