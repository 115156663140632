import React, { useState } from 'react';

import View from './view';

const Controller = ({ ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [voteBlockId, setVoteBlockId] = useState();

  return (
    <View
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      voteBlockId={voteBlockId}
      setVoteBlockId={setVoteBlockId}
      {...rest}
    />
  );
};

export default Controller;
