import { connect } from 'react-redux';

import Controller from './controller';

import { selectors, asyncActions, actions } from 'store';

const mapStateToProps = (state) => ({
  authIsLoading: selectors.getIsAuthLoading(state),
  commonErrors: selectors.getCommonErrors(state),
});

const mapDispatchToProps = {
  authUser: asyncActions.authUser,
  setError: actions.setError,
};

const ConnectedController = connect(mapStateToProps, mapDispatchToProps)(Controller);

export default ConnectedController;
