import { request } from 'utils/api';

const getUserInfo = async () => {
  const response = request({
    url: '/auth/users/me',
    options: {
      method: 'GET',
    },
  });

  return response;
};

export default getUserInfo;
