import { createAsyncThunk } from '@reduxjs/toolkit';

const generateAsyncThunk = (actionName, actionHandler) => {
  const handler = createAsyncThunk(actionName, async (argsData, { rejectWithValue }) => {
    try {
      const response = await actionHandler(argsData);

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  });

  return handler;
};

export default generateAsyncThunk;
