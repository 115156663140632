import { request } from 'utils/api';

const sendVote = async ({ points, nominantId }) => {
  const response = request({
    url: '/vote/list',
    options: {
      method: 'POST',
      data: {
        points,
        nominant: nominantId,
      },
    },
  });

  return response;
};

export default sendVote;
