import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import routeNames from 'constants/routeNames';

import { getLocalStorageItem } from 'helpers/localStorage';

const Controller = ({ children, token }) => {
  const currentLocation = useLocation();
  const history = useHistory();

  const storageToken = getLocalStorageItem('token');
  const isAuthPage = currentLocation.pathName === routeNames.auth;

  useEffect(() => {
    if (!storageToken && !isAuthPage) {
      history.push(routeNames.auth);

      return null;
    }

    history.push(routeNames.personalArea);
  }, []);

  useEffect(() => {
    if (token || storageToken) {
      return null;
    }

    history.push(routeNames.auth);
  }, [token, storageToken]);

  return children;
};

export default Controller;
