import { connect } from 'react-redux';

import Controller from './controller';

import { selectors, asyncActions } from 'store';

const mapStateToProps = (state) => ({
  voteInfoLoading: selectors.getVoteInfoLoading(state),
});

const mapDispatchToProps = {
  sendVote: asyncActions.sendVote,
};

const ConnectedController = connect(mapStateToProps, mapDispatchToProps)(Controller);

export default ConnectedController;
