import React from 'react';

import Header from 'components/Common/Header';

import styles from './styles.module.scss';

const View = ({ children }) => (
  <div className={styles.mainLayout}>
    <Header />
    <div className={styles.content}>{children}</div>
  </div>
);

export default View;
