import { connect } from 'react-redux';

import Controller from './controller';

import { selectors, asyncActions } from 'store';

const mapStateToProps = (state) => ({
  token: selectors.getAuthData(state),
  logOutIsLoading: selectors.getLogoutLoading(state),
  userName: selectors.getUserInfo(state),
});

const mapDispatchToProps = {
  getUserInfo: asyncActions.getUserInfo,
};

const ConnectedController = connect(mapStateToProps, mapDispatchToProps)(Controller);

export default ConnectedController;
