import generateAsyncThunk from 'utils/generateAsyncThunk';

import authUser from 'api/authUser';
import logOutUser from 'api/logOutUser';
import getNominants from 'api/getNominants';
import getUserInfo from 'api/getUserInfo';
import sendVote from 'api/sendVote';

const asyncActions = {
  authUser: generateAsyncThunk('authUser', authUser),
  logOutUser: generateAsyncThunk('logOutUser', logOutUser),
  getNominants: generateAsyncThunk('getNominants', getNominants),
  getUserInfo: generateAsyncThunk('getUserInfo', getUserInfo),
  sendVote: generateAsyncThunk('sendVote', sendVote),
};

export default asyncActions;
