import React, { useEffect } from 'react';

import View from './view';

const Controller = ({ nominant, setVoteBlockId, ...rest }) => {
  useEffect(() => {
    const { id: nominantId } = nominant;
    setVoteBlockId(`${nominantId}vote`);
  }, []);

  return <View {...rest} nominant={nominant} setVoteBlockId={setVoteBlockId} />;
};

export default Controller;
