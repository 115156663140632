import { connect } from 'react-redux';

import View from './view';

import { asyncActions } from 'store';

const mapDispatchToProps = {
  logOutUser: asyncActions.logOutUser,
};

const ConnectedView = connect(null, mapDispatchToProps)(View);

export default ConnectedView;
