import React from 'react';
import Slider, { Handle } from 'rc-slider';

import Text from 'components/Common/Text';
import Button from 'components/Common/Button';
import Loader from 'components/Common/Loader';

import { ReactComponent as HandleSliderIcon } from 'assets/svg/handleSlider.svg';

import getBreakPointSize from 'helpers/getBreakPointSize';

import styles from './styles.module.scss';
import colors from 'styles/colors.module.scss';
import 'rc-slider/assets/index.css';

const S_SCREEN_SIZE = getBreakPointSize('xs');

const getMarksSlider = (screenWidth) => {
  const isXSmallScreenWidth = screenWidth <= S_SCREEN_SIZE;

  const textMarkProps = { color: 'red', weight: 'medium', size: isXSmallScreenWidth ? 'm' : 'l' };

  const marksSlider = {
    [-1]: <Text {...textMarkProps}>_</Text>,
    0: <Text {...textMarkProps}>0</Text>,
    1: <Text {...textMarkProps}>1</Text>,
    2: <Text {...textMarkProps}>2</Text>,
    3: <Text {...textMarkProps}>3</Text>,
    4: <Text {...textMarkProps}>4</Text>,
    5: <Text {...textMarkProps}>5</Text>,
    6: <Text {...textMarkProps}>6</Text>,
    7: <Text {...textMarkProps}>7</Text>,
    8: <Text {...textMarkProps}>8</Text>,
    9: <Text {...textMarkProps}>9</Text>,
    10: <Text {...textMarkProps}>10</Text>,
  };

  return marksSlider;
};

const railStyles = {
  background: colors.BLACK,
  transform: 'translateY(2px)',
  height: 1,
};
const trackStyles = { background: colors.RED };
const dotsStyles = { display: 'none' };
const handleStyles = { border: 'none' };

const getButtonContent = (isLoading) => {
  if (!isLoading) {
    return 'Голосовать';
  }

  return <Loader color={colors.WHITE} height={19} />;
};

const View = ({
  changeSliderVoteValue,
  sliderVoteValue,
  nominantVote,
  nominantId,
  sendVote,
  voteInfoLoading,
  setIsOpen,
  screenWidth,
}) => {
  const marksSlider = getMarksSlider(screenWidth);

  const sendNominantVote = () => {
    if (sliderVoteValue === null || sliderVoteValue === -1) {
      return null;
    }

    setIsOpen(false);
    sendVote({ points: sliderVoteValue, nominantId });
  };

  const defaultSliderValue = nominantVote === null ? -1 : nominantVote;

  return (
    <div className={styles.wrapper}>
      <div className={styles.sliderWrapper}>
        <Slider
          className={styles.slider}
          dots={false}
          min={-1}
          max={10}
          defaultValue={defaultSliderValue}
          disabled={nominantVote !== null}
          step={null}
          marks={marksSlider}
          railStyle={railStyles}
          trackStyle={trackStyles}
          dotStyle={dotsStyles}
          handleStyle={handleStyles}
          onChange={changeSliderVoteValue}
          handle={(props) => {
            const { dragging, ...restProps } = props;

            return (
              <Handle dragging={dragging.toString()} {...restProps}>
                <HandleSliderIcon className={styles.sliderHandle} />
              </Handle>
            );
          }}
        />
      </div>
      <Button
        onClick={sendNominantVote}
        disabled={nominantVote !== null}
        className={styles.voteButton}
      >
        {getButtonContent(voteInfoLoading)}
      </Button>
    </div>
  );
};

export default View;
