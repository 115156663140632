import { connect } from 'react-redux';

import Controller from './controller';

import { selectors } from 'store';

const mapStateToProps = (state) => ({
  nominants: selectors.getNominants(state),
});

const ConnectedController = connect(mapStateToProps)(Controller);

export default ConnectedController;
