import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { setLocalStorageItem } from 'helpers/localStorage';

import routeNames from 'constants/routeNames';

import View from './view';

const Controller = ({ token }) => {
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      return null;
    }

    setLocalStorageItem('token', token);
    history.push(routeNames.personalArea);
  }, [token]);

  return <View />;
};

export default Controller;
