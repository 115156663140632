import React from 'react';

import Text from 'components/Common/Text';
import NominantsListItem from 'components/PersonalArea/NominantsListItem';

import styles from './styles.module.scss';

const View = ({ nominants }) => {
  if (!nominants || !nominants.length) {
    return <Text className={styles.emptyLabel}>Пусто</Text>;
  }

  const nominantsList = nominants.map((nominant) => (
    <NominantsListItem key={nominant.id} nominant={nominant} />
  ));

  return <div className={styles.listContainer}>{nominantsList}</div>;
};

export default View;
