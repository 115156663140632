import React from 'react';

import Layout from 'components/Common/Layout';
import NominantsList from 'components/PersonalArea/NominantsList';
import SectionTitle from 'components/PersonalArea/SectionTitle';
import Loader from 'components/Common/Loader';

import colors from 'styles/colors.module.scss';
import styles from './styles.module.scss';

const renderPersonalAreaPage = (isLoading) => {
  if (isLoading) {
    return <Loader color={colors.BLACK} height={30} />;
  }

  return (
    <div className={styles.personalAreaSection}>
      <SectionTitle />
      <NominantsList />
    </div>
  );
};

const View = ({ isLoading }) => <Layout>{renderPersonalAreaPage(isLoading)}</Layout>;

export default View;
