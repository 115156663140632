import React from 'react';

import ExitButton from 'components/Common/ExitButton';
import ProfileInfo from 'components/Common/ProfileInfo';
import Loader from 'components/Common/Loader';

import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';

import colors from 'styles/colors.module.scss';
import styles from './styles.module.scss';

const getLogOutButton = (logOutIsLoading) => {
  if (!logOutIsLoading) {
    return <ExitButton />;
  }

  return <Loader color={colors.BLACK} height={30} />;
};

const getProfileInfo = (userName) => {
  if (!userName) {
    return null;
  }

  return <ProfileInfo name={userName} />;
};

const View = ({ isАuthorized, logOutIsLoading, userName }) => (
  <div className={styles.header}>
    <div className={styles.headerContent}>
      <LogoIcon className={styles.logoIcon} />
      {isАuthorized && (
        <div className={styles.profileWrapper}>
          {getProfileInfo(userName)}
          <div className={styles.exitButtonWrapper}>{getLogOutButton(logOutIsLoading)}</div>
        </div>
      )}
    </div>
  </div>
);

export default View;
