import { request } from 'utils/api';

const authUser = async ({ email, password }) => {
  const response = request({
    url: '/auth-token/token/login',
    options: {
      method: 'POST',
      data: {
        password,
        email,
      },
    },
  });

  return response;
};

export default authUser;
