import React from 'react';

import Text from 'components/Common/Text';
import NominantContent from 'components/PersonalArea/NominantContent';
import NominantBottomBar from 'components/PersonalArea/NominantBottomBar';

import styles from './styles.module.scss';

const View = ({ nominant, isOpen, setIsOpen, voteBlockId, setVoteBlockId }) => {
  const { name, vote } = nominant;

  return (
    <div className={styles.container}>
      <Text className={styles.nameLabel}>{name}</Text>
      <NominantBottomBar
        vote={vote}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        voteBlockId={voteBlockId}
      />
      <NominantContent
        nominant={nominant}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        voteBlockId={voteBlockId}
        setVoteBlockId={setVoteBlockId}
      />
    </div>
  );
};

export default View;
