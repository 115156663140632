const contentFields = [
  {
    label: 'Направление',
    field: 'theme',
  },
  {
    label: 'Категории',
    field: 'category',
  },
  {
    label: 'Логотип и фотографии',
    isPhoto: true,
  },
  {
    label: 'Краткое описание продукта',
    field: 'description',
    isHorizontal: true,
  },
  {
    label: 'Опишите конечного потребителя на которого ориентирован данный продукт',
    field: 'question1',
    isHorizontal: true,
  },
  {
    label:
      'Опишите преимущества для конечного потребителя для которого разрабатывался данный продукт',
    field: 'question2',
    isHorizontal: true,
  },
  {
    label: 'Что делает это нововведение уникальным? как это превосходит конкурирующие продукты',
    field: 'question3',
    isHorizontal: true,
  },
  {
    label: 'Объясните дизайн технологию и функциональность продукта',
    field: 'question4',
    isHorizontal: true,
  },
  {
    label:
      'Можете ли вы дать количественную оценку измеренных или предполагаемых преимуществ по сравнению с конкурентами или предыдущими конструкциями / технологиями',
    field: 'question5',
    isHorizontal: true,
  },
  {
    label:
      'Если ваша инновация была протестирована сторонней экспертной организацией, предоставьте контактную информацию для справки или прямую ссылку на результаты',
    field: 'question6',
    isHorizontal: true,
  },
  {
    label:
      'Когда это нововведение было широко представлено на рынке общественного питания в России (месяц, год)',
    field: 'question7',
    isHorizontal: true,
  },
  {
    label:
      'Предоставить 2-х операторов общественного питания, которые имеют опыт использования данного нововведения и уже оценили его преимущества (включая контактную информацию, ФИО, должность и телефон) (если таковы имеются)',
    field: 'question8',
    isHorizontal: true,
  },
];

export { contentFields };
