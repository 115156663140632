import React, { useState, useEffect } from 'react';

import { getLocalStorageItem } from 'helpers/localStorage';

import View from './view';

const Controller = ({ token, getUserInfo, ...rest }) => {
  const [isАuthorized, setIsАuthorized] = useState(false);

  useEffect(() => {
    const isToken = Boolean(getLocalStorageItem('token'));

    if (!isToken) {
      return null;
    }

    setIsАuthorized(isToken);
    getUserInfo();
  }, [token]);

  return <View isАuthorized={isАuthorized} {...rest} />;
};

export default Controller;
