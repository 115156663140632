import React from 'react';
import cn from 'classnames';

import Text from 'components/Common/Text';

import styles from './styles.module.scss';

const View = ({ children, disabled, className, type, textSize, ...rest }) => (
  <button type={type} className={cn(styles.button, className)} disabled={disabled} {...rest}>
    <Text size={textSize} className={styles.text} color={disabled ? 'black' : 'white'}>
      {children}
    </Text>
  </button>
);

export default View;
