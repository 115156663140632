import React, { useState, useEffect } from 'react';

import View from './view';

const Controller = ({ ...rest }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [sliderVoteValue, setSliderVoteValue] = useState(null);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const changeSliderVoteValue = (value) => setSliderVoteValue(value);

  return (
    <View
      changeSliderVoteValue={changeSliderVoteValue}
      sliderVoteValue={sliderVoteValue}
      screenWidth={screenWidth}
      {...rest}
    />
  );
};

export default Controller;
