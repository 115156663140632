import React from 'react';
import cn from 'classnames';

import InfoBlock from 'components/PersonalArea/InfoBlock';
import PhotoBlock from 'components/PersonalArea/PhotoBlock';
import SliderVote from 'components/PersonalArea/SliderVote';

import { contentFields } from './helpers';

import styles from './styles.module.scss';

const getContentBlocks = (nominant) => {
  const blocks = contentFields.map(({ label, field, isHorizontal, isPhoto }) => {
    const { id, logo, photo, presentation, video } = nominant;

    if (isPhoto) {
      return (
        <PhotoBlock
          key={`${id}-${field}`}
          label={label}
          logo={logo}
          photo={photo}
          presentation={presentation}
          video={video}
        />
      );
    }

    return (
      <InfoBlock
        key={`${id}-${field}`}
        label={label}
        value={nominant[field]}
        isHorizontal={isHorizontal}
      />
    );
  });

  return blocks;
};

const View = ({ nominant, isOpen, voteBlockId, setIsOpen }) => {
  const { id, vote } = nominant;

  const openStyle = !isOpen ? styles.hidden : null;
  const contentBlocks = getContentBlocks(nominant);

  return (
    <div className={cn(styles.container, openStyle)}>
      {contentBlocks}
      <section id={voteBlockId} className={styles.voteSection}>
        <SliderVote nominantId={id} nominantVote={vote} setIsOpen={setIsOpen} />
      </section>
    </div>
  );
};

export default View;
