import React from 'react';
import cn from 'classnames';

import Text from 'components/Common/Text';

import getBreakPointSize from 'helpers/getBreakPointSize';

import styles from './styles.module.scss';

const urlRegEx = new RegExp(
  '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
  'i',
);

const S_SCREEN_SIZE = getBreakPointSize('s');

const getTextWithLinks = (text) => {
  const formattedText = text.split(' ').map((word, index) => {
    const isUrl = urlRegEx.test(word);

    if (!isUrl) {
      return `${word} `;
    }

    return (
      <a
        key={index}
        className={styles.textUrl}
        href={word}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text color="red">Ссылка&nbsp;</Text>
      </a>
    );
  });

  return formattedText;
};

const View = ({ label, value, isHorizontal, screenWidth }) => {
  const isSmallScreen = screenWidth <= S_SCREEN_SIZE;
  const horizontalSyle = isHorizontal && !isSmallScreen ? styles.horizontal : null;
  const labelSize = isHorizontal && !isSmallScreen ? null : 'xs';
  const labelColor = isHorizontal && !isSmallScreen ? 'black' : 'darkGrey';
  const textWithLinks = getTextWithLinks(value);

  return (
    <div className={cn(styles.infoContainer, horizontalSyle)}>
      <Text className={cn(styles.infoLabel, horizontalSyle)} size={labelSize} color={labelColor}>
        {label}
      </Text>
      <div className={cn(styles.valueContainer, horizontalSyle)}>
        <Text>{textWithLinks}</Text>
      </div>
    </div>
  );
};

export default View;
