import { request } from 'utils/api';

const logOutUser = async () => {
  const response = request({
    url: '/auth-token/token/logout/',
    options: {
      method: 'POST',
    },
  });

  return response;
};

export default logOutUser;
