import React from 'react';

import Text from 'components/Common/Text';

import getBreakPointSize from 'helpers/getBreakPointSize';

import styles from './styles.module.scss';

const S_SCREEN_SIZE = getBreakPointSize('s');

const getNominantsText = (votedCount, totalCount) => `${votedCount ?? 0} | ${totalCount ?? 0}`;

const View = ({ nominants, screenWidth }) => {
  if (!nominants) {
    return null;
  }

  const isSmallScreenWidth = screenWidth <= S_SCREEN_SIZE;
  const totalCountNominants = nominants.length;
  const votedCountNominants = nominants.filter(({ vote }) => vote !== null).length;
  const mainTitleSize = isSmallScreenWidth ? 'l' : 'xl';

  return (
    <div className={styles.titleContainer}>
      <Text size={mainTitleSize} weight="bold">
        Личный кабинет
      </Text>
      <div className={styles.votes}>
        <Text size="xs" color="darkGrey" className={styles.votesText}>
          Голоса
        </Text>
        <Text size="l" color="red">
          {getNominantsText(votedCountNominants, totalCountNominants)}
        </Text>
      </div>
    </div>
  );
};

export default View;
