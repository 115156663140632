import { connect } from 'react-redux';

import { selectors } from 'store';

import View from './view';

const mapStateToProps = (state) => ({
  nominants: selectors.getNominants(state),
});

const ConnectedView = connect(mapStateToProps)(View);

export default ConnectedView;
