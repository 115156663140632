import { request } from 'utils/api';

const getNominantList = async () => {
  const response = request({
    url: '/nominant/list',
    options: {
      method: 'GET',
    },
  });

  return response;
};

export default getNominantList;
