import React from 'react';

import Layout from 'components/Common/Layout';
import Text from 'components/Common/Text';
import AuthForm from 'components/Auth/AuthForm';

import styles from './styles.module.scss';

const View = () => (
  <Layout>
    <div className={styles.authContainer}>
      <Text size="xl" weight="bold" className={styles.authTitle}>
        Вход в личный кабинет
      </Text>
      <AuthForm />
    </div>
  </Layout>
);

export default View;
