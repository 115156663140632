import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

const sizes = {
  xl: styles.xlarge,
  l: styles.large,
  m: styles.middle,
  s: styles.small,
  xs: styles.xsmall,
};

const weights = {
  bold: styles.bold,
  medium: styles.medium,
  light: styles.light,
};

const colors = {
  red: styles.red,
  black: styles.black,
  white: styles.white,
  grey: styles.grey,
  darkGrey: styles.darkGrey,
  lightGrey: styles.lightGrey,
};

const View = ({ children, weight, color = 'black', size = 'm', className }) => {
  const sizeStyle = sizes[size];
  const weigtStyle = weights[weight];
  const colorStyle = colors[color];
  const textStyles = cn(styles.text, sizeStyle, weigtStyle, colorStyle, className);

  return <span className={textStyles}>{children}</span>;
};

export default View;
