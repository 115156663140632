import React from 'react';

import Text from 'components/Common/Text';

import { ReactComponent as ExitIcon } from 'assets/svg/exit.svg';

import styles from './styles.module.scss';

const View = ({ logOutUser }) => (
  <button className={styles.exitButton} onClick={logOutUser}>
    <ExitIcon className={styles.exitIcon} />
    <Text color="grey" size="xs" className={styles.exitText}>
      Выйти
    </Text>
  </button>
);

export default View;
